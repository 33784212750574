@import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100;0,400;1,300&display=swap');

.container {
    max-width: 800px;
    margin-left: 2%;
}

.accordion {
    width: 100%;
    max-width: 90%;
    min-width: 600px;
    margin: auto;
    margin-top: 2%;
    color: #ffffff;
    
}

.accordionHeading {
    padding: 14px 0;
    border-style: solid none  solid none;
    border-width: 2px;
    border-color: rgba(126, 126, 126, 0.431) 
}

.accordionHeading .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}

.accordionHeading p {
    font-size: 1.3rem;
    letter-spacing: 2px;
    font-family: 'SpaceGrotesk';
    min-width : 30vw;
    max-width : 81vw;
    
}

.accordionHeading span {
    cursor: pointer;
    position: absolute;
    left: 90%;
    font-size: 2.5rem;
    
}

.accordionContent {
    background-color: black;
    border-style: solid;
    border-width: 1px;
    border-color: #c16777;
    height: 0;
    opacity: 0;
    display: flex;
    overflow: scroll;
    transition: all 800ms ease-in-out;
    border-radius: 5px;
}

.accordionContent.show {
    height: fit-content;
    opacity: 3;
    
}

.accordionContent p {
    font-size: 1.1rem;;
    min-width: 60vw;
    max-width: 60vw;
    font-family: 'SpaceGrotesk';
}

@media screen and (max-width: 1025px) {

    .faq-page-container{
        margin-top: 12%;
    }
    .accordion {

        min-width: 00px;
       
        
    }

    .accordionHeading p {
        font-size: 1rem;;
    }



    .accordionContent p {
        font-size: 1rem;
        min-width: 80vw;
        max-width: 80vw;
    
}
}