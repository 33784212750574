.faq-page-container{
    margin-bottom: 20%;
}



.faq-header{
    text-align: center;
    margin-top: 7%;
    margin-bottom: 7%;
}


.faq-container{
    width: 75vw;

    margin: auto;
}



@media screen and (max-width: 1025px) {
    .faq-container{
        width: 101vw;
    }

}